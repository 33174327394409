import { cloneDeep } from "lodash";

/**
 * @typedef Menu
 * @prop {string} name nome que vai aparecer no menu para o usuário
 * @prop {string} color se for primeiro nível de menus, cor da borda esquerda
 * @prop {string} icon se for primeiro nível de menus, ícone que aparece a esquerda do `name`
 * @prop {string} to nome da rota para a qual direcionar o usuário ao clicar no menu
 * @prop {Menu[]} children submenus
 */

/** @type Menu[] */
const visaoConsultor = [
  {
    name: "Cadastro",
    color: "#e5cb5e",
    icon: "mdi-square-edit-outline",
    to: null,
    children: [
      {
        name: "Clientes",
        to: "listagem-empresas",
      },
      {
        name: "Grupo de Empresas",
        to: "listagem-grupos-de-empresas",
      },
      {
        name: "Consultores",
        to: "listagem-consultores",
      },
      {
        name: "Usuários do Sistema",
        to: "listagem-usuarios",
      },
      {
        name: "Credenciais",
        to: "listagem-credenciais",
      },
      {
        name: "Regularidade Fiscal",
        to: "listagem-certidoes",
      },
    ],
  },
  {
    name: "Dashboards e Relatórios",
    color: "#EF8065",
    icon: "mdi-clipboard-text-outline",
    to: null,
    children: [
      {
        name: "Dashboard Geral",
        to: "dashboard-geral",
      },
      {
        name: "Status e Inconsistencias",
        to: "inconsistencias",
      },
      {
        name: "Relatórios Exportados",
        to: "listagem-relatorios-assincronos",
      },
      {
        name: "Dispêndios",
        to: null,
        children: [
          {
            name: "Resumo de P&D",
            to: "resumo-ped",
          },
          {
            name: "Valor Aproveitado de Incentivo",
            to: "valor-incentivado",
          },
          {
            name: "Serviços Terceiros Detalhados",
            to: "servicos-terceiros-detalhados",
          },
          {
            name: "Materiais Detalhados",
            to: "materiais-detalhados",
          },
          {
            name: "Consumo de Materiais Detalhado",
            to: "consumo-materiais-detalhados",
          },
          {
            name: "Resumo Gastos Projeto",
            to: "resumo-gastos-por-projeto",
          },
          {
            name: "Resumo RH Projeto",
            to: "resumo-rh-por-projeto",
          },
          {
            name: "Resumo RH Agrupamento",
            to: "resumo-rh-por-agrupamento",
          },
          {
            name: "Gastos com Terceiros",
            to: "gastos-terceiros",
          },
          {
            name: "Gastos com Materiais",
            to: "gastos-materiais",
          },
        ],
      },
      {
        name: "Pesquisadores",
        to: null,
        children: [
          {
            name: "Cálculo Incremento",
            to: "calculo-pesquisadores",
          },
          {
            name: "Cálc. Incremento Detalhado",
            to: "calculo-pesquisadores-detalhado",
          },
        ],
      },
      {
        name: "Log de Horas",
        to: null,
        children: [
          {
            name: "por Projeto",
            to: "horas-logadas-por-projeto",
          },
          {
            name: "por Colaborador",
            to: "horas-logadas-por-colaborador",
          },
        ],
      },
      {
        name: "Faturamento Incentivado",
        to: null,
        children: [
          {
            name: "Resumo do Faturamento Incentivado",
            to: "resumo-geral-li",
          },
          {
            name: "Dispêndio de LI para RDA",
            to: "relatorio-dispendios",
          },
          {
            name: "Cumprimento de Obrigação de P&D",
            to: "cumprimento-obrigacao",
          },
          {
            name: "Resumo de Crédito Financeiro Trimestral",
            to: "resumo-credito-financeiro-trimestral",
          },
        ],
      },
      {
        name: "Colaboradores",
        to: null,
        children: [
          {
            name: "Dedicações",
            to: "relatorio-dedicacoes",
          },
          {
            name: "Unidades",
            to: "relatorio-unidades",
          },
          {
            name: "Grupos de Encargos",
            to: "relatorio-grupos-encargos",
          },
          {
            name: "Tipo RH",
            to: "relatorio-tipo-rh",
          },
        ],
      },
    ],
  },
  {
    name: "Colaboradores",
    color: "#EF4848",
    icon: "mdi-account-box-multiple-outline",
    to: "listagem-colaboradores",
  },
  {
    name: "Projetos",
    color: "#BC3588",
    icon: "mdi-file-document-outline",
    to: null,
    children: [
      {
        name: "Análise",
        to: "listagem-projetos-analise",
      },
      {
        name: "Enquadrados",
        to: "listagem-projetos-enquadrados",
      },
    ],
  },
  {
    name: "Dispêndios",
    color: "#96D4E6",
    icon: "mdi-percent-outline",
    to: null,
    children: [
      {
        name: "com Recursos Humanos",
        to: "dispendio-rh",
      },
      {
        name: "com Materiais",
        to: "dispendio-materiais",
      },
      {
        name: "com Serviços Terceiros",
        to: "dispendio-servicos-terceiros",
      },
      {
        name: "com Equipamentos/Softwares",
        to: "listagem-dispendio-equipamentos-softwares",
      },
      {
        name: "com Consumo de Materiais",
        to: "dispendio-consumo-materiais",
      },
      {
        name: "Rubricas",
        to: null,
        children: [
          {
            name: "Padrão do Sistema",
            to: "rubricas-padrao-sistema",
          },
          {
            name: "Pareamento de Rubricas",
            to: "pareamento-rubricas",
          },
          {
            name: "Catálogo de Rubricas",
            to: "catalogo-rubricas",
          },
        ],
      },
      {
        name: "Grupo Encargos Previdenciários",
        to: "dispendio-encargos-previdenciarios",
      },
      {
        name: "Cadastro de Fornecedores e Terceiros",
        to: "dispendio-fornecedores-terceiros",
      },
      {
        name: "LALUR",
        to: "lalur",
      },
      {
        name: "Projetos Externos",
        to: null,
        children: [
          {
            name: "Cadastro de Instituições",
            to: "cadastro-instituicao",
          },
          {
            name: "Cadastro de Projetos Externos",
            to: "cadastro-projeto-externo",
          },
          {
            name: "Tipo Boleto",
            to: "tipo-boleto",
          },
          {
            name: "Tipo Convenio",
            to: "tipo-convenio",
          },
        ],
      },
    ],
  },
  {
    name: "Timesheets",
    color: "#6C92C5",
    icon: "mdi-clock-outline",
    to: null,
    children: [
      {
        name: "Alocação de Horas",
        to: "timesheets",
      },
      {
        name: "Etapas/Atividades",
        to: "atividades",
      },
      {
        name: "Colaboradores por Projeto",
        to: "conferencia-mensal",
      },
    ],
  },
  {
    name: "Faturamento",
    color: "#3C4196",
    icon: "mdi-currency-usd",
    to: null,
    children: [
      {
        name: "Produtos Incentivados",
        to: "listagem-produtos-incentivados",
      },
      {
        name: "Catálogo de Itens",
        to: "catalogo-itens",
      },
      {
        name: "Notas Fiscais",
        to: null,
        children: [
          {
            name: "Notas Fiscais",
            to: "listagem-notas-fiscais",
          },
          {
            name: "Sequência de Notas Fiscais",
            to: "listagem-sequencia-notas-fiscais",
          },
        ],
      },
      {
        name: "Devoluções",
        to: null,
        children: [
          {
            name: "Notas Fiscais de Devolução",
            to: "listagem-notas-fiscais-devolucao",
          },
          {
            name: "Conciliação de Devoluções Incentivadas",
            to: "conciliacao-devolucoes",
          },
        ],
      },
      {
        name: "Cálculo de Fat. Incentivado",
        to: null,
        children: [
          {
            name: "Status de Fat. Incentivado",
            to: "status-faturamento-incentivado",
          },
          {
            name: "Faturamento de Prod. Incentivados",
            to: "faturamento-produtos-incentivados",
          },
        ],
      },
      {
        name: "Crédito Financeiro",
        to: null,
        children: [
          {
            name: "Novo Sigplani Produtos",
            to: "sigplani-produtos",
          },
          {
            name: "Resumo Crédito Financeiro — Sigplani",
            to: "resumo-credito-financeiro",
          },
        ],
      },
      {
        name: "CFOPs",
        to: "listagem-cfops",
      },
    ],
  },
  {
    name: "Importações",
    color: "#28A1B5",
    icon: "mdi-tray-arrow-down",
    to: "listagem-importacoes",
  },
];

/** @type Menu[] */
const visaoCliente = [
  {
    name: "Minha Conta",
    color: "#E5CB5E",
    icon: "mdi-square-edit-outline",
    to: "minha-conta",
  },
  {
    name: "Dashboards e Relatórios",
    color: "#EF8065",
    icon: "mdi-clipboard-text-outline",
    to: null,
    children: [
      {
        name: "Dashboard Geral",
        to: "dashboard-geral",
      },
      {
        name: "Relatórios Exportados",
        to: "listagem-relatorios-assincronos",
      },
      {
        name: "Log de Horas",
        to: null,
        children: [
          {
            name: "por Projeto",
            to: "horas-logadas-por-projeto",
          },
          {
            name: "por Colaborador",
            to: "horas-logadas-por-colaborador",
          },
        ],
      },
      {
        name: "Investimentos em P&D",
        to: null,
        children: [
          {
            name: "Resumo de P&D",
            to: "resumo-ped",
          },
          {
            name: "Resumo de Gastos por Projeto",
            to: "resumo-gastos-por-projeto",
          },
          {
            name: "Resumo de RH por Projeto",
            to: "resumo-rh-por-projeto",
          },
          {
            name: "Controle de Gastos com Materiais",
            to: "gastos-materiais",
          },
          {
            name: "Controle de Gastos com Terceiros",
            to: "gastos-terceiros",
          },
          {
            name: "Materiais Detalhados",
            to: "materiais-detalhados",
          },
          {
            name: "Consumo de Materiais Detalhados",
            to: "consumo-materiais-detalhados",
          },
          {
            name: "Serviços de Terceiros Detalhados",
            to: "servicos-terceiros-detalhados",
          },
        ],
      },
      {
        name: "Faturamento Incentivado",
        to: null,
        children: [
          {
            name: "Resumo do Faturamento Incentivado",
            to: "resumo-geral-li",
          },
          {
            name: "Dispêndio de LI para RDA",
            to: "relatorio-dispendios",
          },
          {
            name: "Cumprimento de Obrigação de P&D",
            to: "cumprimento-obrigacao",
          },
          {
            name: "Resumo de Crédito Financeiro Trimestral",
            to: "resumo-credito-financeiro-trimestral",
          },
        ],
      },
    ]
  },
  {
    name: "Recursos Humanos",
    color: "#E5CB5E",
    icon: "mdi-account-box-multiple-outline",
    to: null,
    children: [
      {
        name: "Folha de Pagamento",
        to: "listagem-importacoes-fopag",
      },
      {
        name: "Provisões",
        to: "listagem-importacoes-provisoes",
      },
      {
        name: "Controle de Ponto",
        to: "listagem-importacoes-ponto",
      },
    ]
  },
  {
    name: "Projetos",
    color: "#96D4E6",
    icon: "mdi-file-document-outline",
    to: null,
    children: [
      {
        name: "Análise",
        to: "listagem-inclusao",
      },
      {
        name: "Enquadrados",
        to: "listagem-projetos-enquadrados",
      },
    ]
  },
  {
    name: "Timesheet",
    color: "#6C92C5",
    icon: "mdi-clock-outline",
    to: null,
    children: [
      {
        name: "Alocação de Horas",
        to: "listagem-alocar-horas",
      },
      {
        name: "Etapas / Atividades",
        to: "atividades",
      },
      {
        name: "Importação",
        to: "listagem-importacoes-timesheet",
      },
      {
        name: "Colaboradores por Projeto",
        to: "colaboradores-por-projeto",
      },
    ]
  },
  {
    name: "Dispêndios",
    color: "#1AF0F0",
    icon: "mdi-percent-outline",
    to: null,
    children: [
      {
        name: "com Serviços de Terceiros",
        to: null,
        children: [
          {
            name: "Anexar notas fiscais (PDF)",
            to: "listagem-importacoes-servicos-terceiros-anexo",
          },
          {
            name: "Importar relatório de notas fiscais",
            to: "listagem-importacoes-servicos-terceiros-planilha",
          },
        ]
      },
      {
        name: "com Materiais",
        to: null,
        children: [
          {
            name: "Anexar notas fiscais (PDF)",
            to: "listagem-importacoes-materiais-anexo",
          },
          {
            name: "Importar relatório de notas fiscais",
            to: "listagem-importacoes-materiais",
          },
          {
            name: "Importar relatório de consumo de materiais",
            to: "listagem-importacoes-consumo-materiais",
          },
        ]
      },
      {
        name: "Registros Contábeis",
        to: null,
        children: [
          {
            name: "Razão Contábil",
            to: "listagem-importacoes-razao-contabil",
          },
          {
            name: "LALUR",
            to: "listagem-importacoes-lalur",
          },
        ],
      },
    ]
  },
  {
    name: "Faturamento",
    color: "#6C92C5",
    icon: "mdi-currency-usd",
    to: null,
    children: [
      {
        name: "Produtos Incentivados",
        to: "listagem-produtos-incentivados",
      },
      {
        name: "Notas Fiscais",
        to: "listagem-notas-fiscais",
      },
      {
        name: "Conciliação de Devoluções Incentivadas",
        to: "conciliacao-devolucoes",
      },
      {
        name: "Faturamento de Prod. Incentivados",
        to: "faturamento-produtos-incentivados",
      },
      {
        name: "Crédito Financeiro",
        to: null,
        children: [
          {
            name: "Novo Sigplani Produtos",
            to: "sigplani-produtos",
          },
          {
            name: "Resumo Crédito Financeiro — Sigplani",
            to: "resumo-credito-financeiro",
          },
        ]
      },
      {
        name: "Importação XML",
        to: "listagem-importacoes-faturamento",
      },
    ]
  },
];

export const CLIENTE = cloneDeep(visaoCliente);
export const GALAPOS = cloneDeep(visaoConsultor);

const visaoSaaS = cloneDeep(visaoConsultor)
const sassTimesheets = visaoSaaS.find(({ name }) => name === "Timesheets");
sassTimesheets.children.splice(0, 1, { name: "Alocação de Horas", to: "listagem-alocar-horas" });
sassTimesheets.children.splice(3, 0, { name: "Conferencia Mensal", to: "conferencia-mensal" });
export const SAAS = cloneDeep(visaoSaaS);
